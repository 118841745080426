import { graphql, Link, StaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { Facebook, Linkedin, Reddit, Twitter } from 'react-social-sharing';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import styles from './Post.module.scss';
import Tags from './Tags';

const PurePost = ({ post, data }) => {
  const { tags, title, date } = post.frontmatter;
  const { slug } = post.fields;
  const { url } = data.site.siteMetadata;

  const { html } = post;
  const { tagSlugs } = post.fields;

  const urlDate = moment(date).format('YYYY/MM/DD');
  const link = `${url}/${urlDate}/${slug}`;
  const shareIcon = {
    link,
    small: true,
    message: title
  };

  return (
    <div className={styles['post']}>
      <div className={styles['post__sidebar']}>
        <Link className={styles['post__home-button']} to="/">
          All Articles
        </Link>
        <div className={styles['post__share-buttons']}>
          <Twitter {...shareIcon} />
          <Reddit {...shareIcon} />
          <Linkedin {...shareIcon} />
          <Facebook {...shareIcon} />
        </div>
      </div>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        <Tags tags={tags} tagSlugs={tagSlugs} />
      </div>

      <div className={styles['post__comments']}>
        <Comments postUrl={link} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export const Post = props => (
  <StaticQuery
    query={graphql`
      query PostQuery {
        site {
          siteMetadata {
            url
          }
        }
      }
    `}
    render={data => <PurePost {...props} data={data} />}
  />
);

export default Post;
